<template>
  <!--個人情報-->
  <div>
    下記内容にご同意の上ご登録ください。
    <v-card outlined max-height="100px" class="overflow-y-auto">
      <v-card-text style="text-align: left; margin: 0">
        <p><b>個人情報の取り扱いについて</b></p>
        <ol>
          <li>
            本フォームでご提供いただく個人情報の利用目的は、（１）学習支援および就業支援サービスを提供するため、（２）弊社が提供するサービスに関する情報を電子メール等で継続的に提供するため、（３）Cookieなどの技術を使用し、当社Webサイトの閲覧履歴を取得・分析して、当社から適切な情報を提供するためです。
          </li>
          <li>当個人情報を第三者に提供することはありません。</li>
          <li>
            当個人情報の取扱いを委託することがあります。委託にあたっては、委託先における個人情報の安全管理が図られるよう、委託先に対する必要かつ適切な監督を行います。
          </li>
          <li>
            当個人情報の利用目的の通知、開示、内容の訂正・追加または削除、利用の停止・消去および第三者への提供の停止（「開示等」といいます。）を受け付けております。開示等の求めは、以下の「個人情報苦情及び相談窓口」で受け付けます。
          </li>
          <li>
            任意項目の情報のご提供がない場合、最適なご回答ができない場合があります。
          </li>
        </ol>
        【個人情報保護管理者】
        <br />株式会社UZUZ COLLEGE
        <br />代表取締役社長 川畑 翔太郎
        <br />
        <br />【個人情報苦情及び相談窓口】
        <br />代表取締役社長 川畑 翔太郎
        <br />TEL：03-4214-3150
        <br />FAX：03-5333-0803
        <br />Mail：info@uzuz-college.jp
        <br />（受付時間9時～18時土日祝日除く／営業のお電話はお断りいたします）
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'PrivacyPolicy',
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>
